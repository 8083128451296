import { getAuth } from "firebase/auth";
import { firebaseFirestore } from "../package/firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

export async function getAddresses() {
  const user = getAuth().currentUser;
  if (user === null) return false;
  try {
    const usersRef = doc(firebaseFirestore, "users", user.uid);
    const userDoc = await getDoc(usersRef);
    if (!userDoc.exists()) {
      console.error("該当するユーザーが見つかりませんでした");
      return false;
    }
    const userData = userDoc.data();
    return userData.addresses;
  } catch {
    console.error("ユーザー情報の取得中にエラーが発生しました");
    return false;
  }
}

export async function getAllUsers() {
  const user = getAuth().currentUser;
  if (user === null) return false;
  if (!(await checkAdminUser(user.uid))) return false;
  try {
    const usersRef = collection(firebaseFirestore, "users");
    const userDocs = await getDocs(usersRef);
    const usersData = userDocs.docs.map((doc) => doc.data());
    return usersData;
  } catch (error) {
    console.error("getAllUsers(): ", error);
    return false;
  }
}

export async function getUserByUid(uid) {
  try {
    const usersRef = doc(firebaseFirestore, "users", uid);
    const userDoc = await getDoc(usersRef);
    if (!userDoc.exists()) {
      return null;
    }
    const userData = userDoc.data();
    return userData;
  } catch {
    console.error("ユーザー情報の取得中にエラーが発生しました。");
    return null;
  }
}

export async function isAdmin() {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user === null) {
      console.warn("現在のユーザーが存在しません");
      return false;
    }
    const usersRef = doc(firebaseFirestore, "users", user.uid);
    const userDoc = await getDoc(usersRef);
    if (!userDoc.exists()) {
      console.error(`該当するユーザーが見つかりませんでした`);
      return false;
    }
    const userData = userDoc.data();
    return userData?.isAdmin || false; // userDataがundefinedの場合にもfalseを返す
  } catch (error) {
    console.error("ユーザー情報の取得中にエラーが発生しました:", error);
    return false;
  }
}

export async function checkAdminUser(uuid) {
  if (!uuid) return false;
  try {
    const usersRef = doc(firebaseFirestore, "users", uuid);
    const userDoc = await getDoc(usersRef);
    if (!userDoc.exists()) {
      console.error("該当するユーザーが見つかりませんでした");
      return false;
    }
    const userData = userDoc.data();
    return userData.isAdmin;
  } catch {
    console.error("管理者ユーザー情報の取得中にエラーが発生しました");
    return false;
  }
}
