import { getAuth } from "firebase/auth";
import { BACKEND_URL } from "../const/const";
import {
  ALREADY_EXISTED_USER_MSG,
  LACK_OF_POINT_MSG,
  NOT_LOGINED_MSG,
} from "../const/error-message";
import { isExistUser } from "./auth";
import { getPoint } from "./point";
import { getUserByUid } from "./user";

export const editAddress = async (index, address) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/edit-address`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      index,
      data: { ...address },
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const addAddress = async (address) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/add-address`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: { ...address },
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const deleteAddress = async (index) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/delete-address`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      index,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const createUser = async () => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  if (await isExistUser(user)) throw new Error(ALREADY_EXISTED_USER_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/create-user`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const createUserWithYT = async (subscriberCount) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const userDoc = await getUserByUid(user.uid);
  if (
    (await isExistUser(user)) &&
    userDoc &&
    userDoc.isRegisteredSubscriberCount
  )
    throw new Error(ALREADY_EXISTED_USER_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/create-user-with-yt`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: { subscriberCount: subscriberCount },
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const selectAddress = async (index) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/select-address`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      index,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const roulette = async (packageId, times) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const results = await Promise.all([getPoint(user.uid), user.getIdToken()]);
  const pointOfCurrentUser = results[0];
  const token = results[1];
  if (!pointOfCurrentUser) throw new Error(LACK_OF_POINT_MSG);
  const res = await fetch(`${BACKEND_URL}/roulette/${packageId}/${times}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const getPackages = async () => {
  const res = await fetch(`${BACKEND_URL}/packages`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

// TODO: 後で消す - 最初からBackには認証なかったので、Frontend側だけで変更
export const getCardList = async (packageId) => {
  const res = await fetch(`${BACKEND_URL}/card-list/${packageId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const exchange = async (contentIds) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/exchange`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      contentIds,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const requestShipment = async (contentIds) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/request-shipment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      contentIds,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const addPackage = async (
  name,
  description,
  price,
  img,
  tags,
  contents
) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/add-package/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      description,
      price,
      img,
      tags,
      contents,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const editPackage = async (
  packageId,
  name,
  description,
  price,
  img,
  tags,
  contents
) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/edit-package`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      packageId,
      name,
      description,
      price,
      img,
      tags,
      contents,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const shipCard = async (holderId, contentId, invoiceNumber) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/ship-card`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      holderId,
      contentId,
      invoiceNumber,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const cancelShipment = async (holderId, contentId) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/cancel-shipment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      holderId,
      contentId,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};

export const editPoint = async (packageId, contentId, value) => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/edit-point`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      packageId,
      contentId,
      value,
    }),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};
