import React, { useState } from "react";
import "../../styles/FormForPayment.css";

/**
 * 有効期限の表示を整形するための補助関数
 */
const formatExpiryDisplay = (value) => {
  const numeric = value.replace(/\D/g, "");
  if (numeric.length <= 2) {
    return numeric;
  } else {
    return numeric.slice(0, 2) + "/" + numeric.slice(2);
  }
};

/**
 * formForPayment:
 * カード情報の入力フォームを表示し、入力内容を親(MyPayment)に渡す
 */
export const FormForPayment = ({ onSubmitPayment, loading, amount }) => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [cardName, setCardName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitPayment(cardNumber, cardExpiry, cardCvc, cardName);
  };

  return (
    <div className="formforpayment-container">
      <form onSubmit={handleSubmit} className="formforpayment-form">
        <h2 className="formforpayment-title">お支払い情報入力</h2>

        <div className="formforpayment-field">
          <label htmlFor="cardNumber" className="formforpayment-label">
            カード番号
          </label>
          <input
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="1234 5678 9012 3456"
            required
            className="formforpayment-input"
            inputMode="numeric"
            maxLength={16}
            pattern="^\d{1,16}$"
            title="半角数字のみ、最大16桁まで入力できます"
          />
        </div>

        <div className="formforpayment-row">
          <div className="formforpayment-column">
            <label htmlFor="cardExpiry" className="formforpayment-label">
              有効期限
            </label>
            <input
              type="text"
              id="cardExpiry"
              value={formatExpiryDisplay(cardExpiry)}
              onChange={(e) => setCardExpiry(e.target.value)}
              placeholder="MM/YY"
              required
              className="formforpayment-input"
              inputMode="numeric"
              maxLength={5}
              pattern="^(0[1-9]|1[0-2])/\d{2}$"
              title="MM/YY の形式で入力してください (例: 03/25)"
            />
          </div>

          <div className="formforpayment-column">
            <label htmlFor="cardCvc" className="formforpayment-label">
              CVC
            </label>
            <input
              type="text"
              id="cardCvc"
              value={cardCvc}
              onChange={(e) => setCardCvc(e.target.value)}
              placeholder="123"
              required
              className="formforpayment-input"
              inputMode="numeric"
              maxLength={4}
              pattern="^\d{3,4}$"
              title="3桁または4桁の数字で入力してください"
            />
          </div>
        </div>

        <div className="formforpayment-field">
          <label htmlFor="cardName" className="formforpayment-label">
            カード名義
          </label>
          <input
            type="text"
            id="cardName"
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            placeholder="TARO YAMADA"
            required
            className="formforpayment-input"
          />
        </div>

        <button
          type="submit"
          className="formforpayment-button"
          disabled={loading}
        >
          {loading ? "処理中..." : `支払う (¥${amount || ""})`}
        </button>
      </form>
    </div>
  );
};
