import React from "react";

export function SuccessModal({ onClose }) {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl max-w-xl w-4/5"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-6">
          <h2 className="text-xl font-bold">✅</h2>
        </div>
        <div className="px-6 py-4">
          <h2>決済が完了しました。</h2>
          <p>ご購入ありがとうございます。</p>
        </div>
        <div className="px-6 py-4 flex flex-col items-center gap-4">
          <button
            className="w-56 bg-gray-400 text-black font-bold py-2 px-4 rounded-full"
            onClick={onClose}
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
}
