import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { OurSimplifiedColoredHeader } from "../../components/header-simple-color";
import { FormForPayment } from "./formForPayment"; // フォームは別コンポーネント
import { BiChevronLeft } from "react-icons/bi";
import { getAuth } from "firebase/auth";
import { fireauth } from "../../package/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserByUid } from "../../actions/user";
import { addNumberOfPaymentFailure } from "../../actions/backend/user";
import { ERROR_MSG_PREFIX } from "../../const/error-message";

// 成功モーダル
import { SuccessModal } from "../../components/payment/Modal";

export function MyPayment() {
  const hasAPICalledRef = useRef(false);

  // UI制御
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  // ユーザー認証
  const [user, loading, error] = useAuthState(fireauth);

  // ルーター関連
  const params = useParams(); // /payment/:price など
  const navigate = useNavigate();
  const location = useLocation();

  // ------------------------------
  // 1. 認証状態チェック
  // ------------------------------
  useEffect(() => {
    if (loading) return; // ローディング中
    if (error) {
      console.error("Authentication error:", error);
      return;
    }
    if (!user) {
      alert("ログインしていません");
      // navigate("/");
    }
  }, [user, loading, error, navigate]);

  // ------------------------------
  // 2. 3Dセキュアリダイレクト後の処理
  // ------------------------------
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("orderId");
    const isReturn3DS = queryParams.get("redirect");
    const error = queryParams.get("error");

    // エラーチェック
    if (error) {
      let errorMessage = "決済処理中にエラーが発生しました";

      if (error === "token_expired") {
        errorMessage = "セッションがタイムアウトしました。再度お試しください。";
      } else if (error === "capture_failed") {
        errorMessage = "決済処理に失敗しました。カード情報をご確認ください。";
      } else if (error === "data_retrieval_error") {
        errorMessage = "決済情報の取得に失敗しました。";
      } else if (error === "server_error") {
        errorMessage = "サーバーエラーが発生しました。";
      }

      console.error("Payment error:", error);
      if (!hasAPICalledRef.current) {
        hasAPICalledRef.current = true;
        alert(errorMessage);
        // エラー後も同じページに留まる
        return;
      }
    }

    // すでに決済済みなら、フロントでは成功表示だけ行う
    if (isReturn3DS && orderId && !hasAPICalledRef.current) {
      hasAPICalledRef.current = true;

      // サーバー側が決済完了済という前提で成功モーダルを出す
      setPaymentStatus(true);
      setOpenModal(true);
    }
  }, [location.search]);

  // ------------------------------
  // 3. カード決済開始
  //    (Frictionless or 3Dチャレンジ)
  // ------------------------------
  const handlePayment = async (cardNumber, cardExpiry, cardCvc, cardName) => {
    if (!user) {
      alert("ログインしていません");
      navigate("/");
      return;
    }
    setPaymentLoading(true);

    try {
      // (A) 決済失敗回数チェック
      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        throw new Error("ユーザーがログインしていません。");
      }
      const userData = await getUserByUid(currentUser.uid);
      if (userData && userData.numberOfPaymentFailure >= 5) {
        throw new Error("決済に失敗しました（失敗回数超過）");
      }

      // (B) Veritransのトークンサーバへカード情報を送信 → トークン取得
      const tokenResponse = await fetch(
        process.env.REACT_APP_VERITRANS_PAYMENT_URL,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            card_number: cardNumber.replace(/\s/g, ""),
            card_expire: cardExpiry, // MM/YY
            security_code: cardCvc,
            cardholder_name: cardName,
            token_api_key: process.env.REACT_APP_VERITRANS_TOKEN_API_KEY,
            lang: "ja",
          }),
        }
      );

      if (!tokenResponse.ok) {
        if (tokenResponse.status === 400) {
          console.warn("400 error: Card fail count incremented");
          await addNumberOfPaymentFailure().catch((err) => {
            alert(`${ERROR_MSG_PREFIX} ${err.message}`);
          });
        }
        throw new Error(`トークンAPIエラー (status: ${tokenResponse.status})`);
      }

      const tokenData = await tokenResponse.json();
      if (tokenData.status !== "success") {
        throw new Error("トークン取得に失敗しました");
      }

      // (C) 3DS 認可 (authorize-3ds)
      const orderId = `ORDER_${Date.now()}_${Math.random()
        .toString(36)
        .slice(2, 10)}`;

      const idToken = await currentUser.getIdToken();
      const authorizeResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/authorize-3ds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            data: {
              token: tokenData.token,
              amount: params.price,
              orderId,
              cardholderName: cardName,
              cardholderEmail: userData.email,
            },
          }),
        }
      );

      const authJson = await authorizeResponse.json();
      if (!authorizeResponse.ok) {
        throw new Error(authJson?.message || "3DS認可に失敗");
      }

      // (D) チャレンジ要否を判定
      const { redirectUrl } = authJson.data || {};
      if (redirectUrl) {
        // Challenge(追加認証)
        window.location.href = redirectUrl;
        return;
      }

      // Frictionless(チャレンジ不要) → サーバーが即決済 & ポイント加算
      // → フロントは完了モーダルのみ
      setPaymentStatus(true);
      setOpenModal(true);
    } catch (error) {
      console.error("決済エラー:", error);
      let errorMessage = error.message || "決済に失敗しました";
      if (error.data?.error) {
        errorMessage = error.data.error;
        if (error.data.details) {
          console.error("詳細エラー:", error.data.details);
        }
      }
      alert(errorMessage);
      try {
        await addNumberOfPaymentFailure();
      } catch (err) {
        alert(`${ERROR_MSG_PREFIX} ${err.message}`);
      }
      // エラー後も同じページに留まる
    } finally {
      setPaymentLoading(false);
    }
  };

  // 戻るボタン
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="h-screen w-screen">
      <OurSimplifiedColoredHeader />

      <div className="flex flex-col max-w-[800px] mx-auto p-2 md:px-1">
        <div className="w-full flex items-center">
          <button
            className="bg-gray-400 text-white font-bold py-1 px-2 rounded-full flex items-center gap-1"
            onClick={handleGoBack}
          >
            <BiChevronLeft size={18} />
            戻る
          </button>
        </div>

        <h1 className="text-2xl font-bold text-center mt-4">お支払い</h1>

        {paymentStatus && openModal ? (
          // ★ 決済成功モーダル
          <SuccessModal
            onClose={() => {
              setOpenModal(false);
              navigate("/");
            }}
          />
        ) : (
          // ★ カード情報入力フォーム
          <FormForPayment
            onSubmitPayment={handlePayment}
            loading={paymentLoading}
            amount={params.price}
          />
        )}
      </div>
    </div>
  );
}
