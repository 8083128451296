import { getCurrentUser } from "./auth";
import { getAuth } from "firebase/auth";
import { firebaseFirestore } from "../package/firebase";
import { doc, getDoc } from "firebase/firestore";
import { BACKEND_URL, PRICES } from "../const/const";
import {
  INVALID_POINT_PRICE_MSG,
  NOT_LOGINED_MSG,
} from "../const/error-message";

export const createPaymentIntent = async (price) => {
  const parsedPrice = parseInt(price, 10);
  if (!PRICES.includes(parsedPrice)) throw new Error(INVALID_POINT_PRICE_MSG);

  const user = await getCurrentUser();
  if (!user) throw new Error(NOT_LOGINED_MSG);

  const token = await user.getIdToken();

  const res = await fetch(
    `${BACKEND_URL}/create-payment-intent/${parsedPrice}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.uid,
      }),
    }
  );

  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);

  return data;
};

export async function point(point) {
  const user = await getCurrentUser();
  if (user === null) {
    return false;
  }
  let result = false;
  try {
    await fetch(`/add-point`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        point: point,
        userId: user.uid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        result = data;
      });
  } catch (error) {
    console.error(error);
    result = false;
  }
  return result;
}

export async function getPoint(uid) {
  try {
    const usersRef = doc(firebaseFirestore, "users", uid);
    const userDoc = await getDoc(usersRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.point;
    } else {
      console.error("該当するユーザーが見つかりませんでした");
      return 0;
    }
  } catch {
    console.error("ユーザー情報の取得中にエラーが発生しました");
    return 0;
  }
}

/**
 * 3Dセキュア認可を行う関数
 * カード番号を元にイシュア判定を実施し、3Dセキュアの利用可否を判定します
 * @param {string} token - カードトークン
 * @param {string} amount - 金額
 * @param {object} additionalData - 追加データ（カード所有者名など）
 * @returns {Promise<object>} 認可結果
 */
export async function authorize3DSecure(token, amount, additionalData = {}) {
  const user = await getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const idToken = await user.getIdToken();
  try {
    // 注文IDを生成
    const orderId = `ORDER_${Date.now()}_${Math.random().toString(36).slice(2, 10)}`;

    // リクエストデータを構築
    const requestData = {
      data: {
        token: token,
        amount: amount,
        orderId: orderId,
        ...additionalData,
      },
    };

    const response = await fetch(`${BACKEND_URL}/authorize-3ds`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const { message, data } = await response.json();
    if (response.status !== 200)
      throw new Error(message || response.statusText);
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function purchagePoint(
  token,
  amount,
  orderId,
  is3DSVerification = false
) {
  const user = await getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const idToken = await user.getIdToken();
  try {
    // リクエストのデータを構築
    const requestData = is3DSVerification
      ? {
          data: {
            orderId: orderId,
            amount: amount, // 金額も送信
            is3DSVerification: true,
          },
        }
      : {
          data: {
            token: token,
            amount: amount,
            orderId:
              orderId ||
              "ORDER_" + Date.now() + Math.random().toString(36).slice(2, 10),
          },
        };

    const response = await fetch(`${BACKEND_URL}/purchase-point`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const jsonResponse = await response.json();
    const { message, data } = jsonResponse;

    if (response.status !== 200) {
      console.error(`❌ API呼び出し失敗: ${message || response.statusText}`);
      throw new Error(message || response.statusText);
    }

    // 3D Secure検証の場合は文字列"success"を返す
    if (is3DSVerification) {
      if (data === "success") {
        console.log("✅ 3D Secure 2.0");
        return "success";
      } else {
        console.error("❌ 3D Secure検証失敗:", data);
        throw new Error("3D Secure検証に失敗しました");
      }
    }

    return data;
  } catch (error) {
    console.error(`❌ 処理エラー:`, error);
    throw error; // エラーを上位に伝播させる
  }
}
