import { getAuth } from "firebase/auth";
import { BACKEND_URL } from "../../const/const";
import { NOT_LOGINED_MSG } from "../../const/error-message";

export const addNumberOfPaymentFailure = async () => {
  const user = getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/add-number-of-payment-failure`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};
